<template>
  <!-- 主播出走  v-if="roomInfo"-->
  <div class="live-away">
    <!-- <div class="live-away-title">{{roomInfo.title}}</div> -->
    <div class="live-away-txt">主播暂时不在，请稍等片刻~</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "live-away",
  data() {
    return {};
  },
  computed: {
    // ...mapGetters({
    //   roomInfo: "getRoomInfo",
    // }),
  },
  created() {
    console.log('this.roomInfo',this.roomInfo);
  } 
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.live-away {
  width: 100%;
//   top: 27%;
  left: 0;
  position: absolute;
  z-index: 98;
  text-align: center;
  color: #fff;
  height: 100%;
  background: rgba(0,0,0,.4);
}

.live-away-title {
  font-size: 24px;
  margin:0 auto ;
}

.live-away-txt {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 200px;
}

</style>
