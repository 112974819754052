<template>
  <div class="page">
    <div class="video_wrap">
      <VTcPlayer ref="tcPlayer" :options="options" @load="onLoad" @play="onPlay" v-if="isView" />
      <div class="video_drag"  v-drag v-else-if="!isClickClose" >
        <div class="mask"></div>
        <VTcPlayer ref="tcPlayer2" :options="options2" @load="onLoad" @play="onPlay"  />
        <div class="top_tip">
          <p class="tip">按住视频可进行拖动</p>
          <div class="close_drag" @click="isClickClose=true;">
            <i class=" iconfont icon-guanbi"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--Polyfill-->
<!--[if lt IE 9]>
<script src="https://imgcache.qq.com/open/qcloud/video/vcplayer/libs/es5-shim.js"></script>
<script src="https://imgcache.qq.com/open/qcloud/video/vcplayer/libs/es5-sham.js"></script>
<![endif]-->
<script>
import { VTcPlayer } from 'v-tcplayer';
export default {
  name: "live-video",
  props: {
    url: {
      type: String,
      default: ""
    },
    cover: {
      type: String,
      default:
        "https://img.zhanshangxiu.com/showonline/file/202010/20201030/209a518f609b466db8ae37dbae41d0ed.jpg"
    },
    liveStatus: {
      type: Number,
      default: 0
    },
    showBg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
        options:{
          width: 878,
          height: 496,
          autoplay: true,
          m3u8: "",
        },
        options2:{
          width: 515,
          height: 290,
          autoplay: true,
          m3u8: "",
        },
        isClickClose:false,
        videoWarp:null,    //videoWarp元素
        isView:true,      //是否可视
        scrollEvent:null, //滚动事件
    };
  },
  components: {
    VTcPlayer,
  },
  computed: {
    player () {
      // tcPlayer实例
      return this.$refs.tcPlayer.vTcPlayer
    },
  },
  created(){
    this.options = {
      width: 878,
      height: 496,
      autoplay: true,
      m3u8:this.url,
    },
    this.options2 = {
      width: 515,
      height: 290,
      autoplay: true,
      m3u8:this.url,
    }
  },
  mounted() {
    this.$nextTick(()=>{
      // this.onPlay();
      if($('.video_wrap')){
        this.videoWarp=$('.video_wrap')[0];
      }
      $(document).scroll(() => {
        if(!this.videoWarp){
          this.videoWarp=$('.video_wrap')[0];
        }
        this.isView=this.isVisible(this.videoWarp)
        if(this.isView&&this.isClickClose){
          this.isClickClose=false
        }
      });
    })
  },
  beforeDestroy() {
    $(document).unbind(); //停止滚动事件
    this.scrollEvent = null;  
  },
  methods: {
    isVisible(dom) {
      let  scrTop = document.documentElement.scrollTop || document.body.scrollTop;
      return !(scrTop > (dom.offsetTop + dom.offsetHeight) || (scrTop + window.innerHeight) < dom.offsetTop);
    },
    play () {
      this.player.play()
    },
    getCurrentTime () {
      this.player.currentTime()
    },
    onLoad(){},
    onPlay(){
    },
    defaultClick(){

    }
  }
};
</script>

<style lang="scss">
html {
  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.page {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  object-fit: fill;
  .video_wrap{
    width: 100%;
    height: 100%;
  }
  .video_drag{
    position: fixed;
    bottom:30px ;
    right: 105px;
    left: auto;
    top: auto;
    height: 290px;
    width: 515px;
    user-select: none;
    cursor: move;
    z-index: 9999;
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% - 4em);
      z-index: 10;
    }
    &:hover{
      .top_tip{
        opacity: 1;
      }
    }
    .top_tip{
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height:42px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right:8px;
      text-indent: 1em;
      color: #fff;
      font-size: 14px;
      z-index: 11;
    }
    .close_drag{
      width: 24px;
      height: 24px;
      cursor: pointer;
      border-radius: 50%;
      background: rgba($color: #000000, $alpha: .5);
      display: flex;
      align-items: center;
      justify-content: center;
      text-indent: initial;
      &:hover{
        color:#d34242 ;
      }
      i{
        
        transform: scale(0.6);
      }
    }
  }
}
.video-player {
  width: 100%;
  height: 100%;
  background-color: #000;
}
.video_box {
  object-fit: fill !important;
  background: #131825;
  z-index: 88;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.icon_play {
  position: absolute;
  top: 44%;
  right: 0;
  left: 0;
  bottom: auto;
  margin: auto;
  z-index: 90;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.play,
.platStart {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 89;
  width: 100%;
  height: 100%;
}
.play{
  // object-fit: cover;
}
.vcp-player{
  height: 4.3rem;
}
video{
  height: 4.3rem;
}
</style>