<template>
  <div class="compinfo" v-if="actData">
    <!-- 主题活动，主办活动没有公司 -->
    <div class="m-card3" v-if="actData.exhibitor">
      <div class="mc-top">
        <div class="mc-top-title">
          <span class="mc-top-text mc-top-txt s-bold ">公司信息</span>
        </div>
      </div>
      <div class="compinfo-main">
        <div class="top" @click="linkToExhibitorDetails(actData.exhibitor.id)">
          <div class="logo">
            <img :src="actData.company.logo" v-if="actData.company.logo" />
            <img :src="exhibitionInfo.logo" v-else />
          </div>
          <div>
            <div class="compinfo-name">{{ actData.company.name }}</div>
            <div class="compinfo-type">
              <div class="u-booth" v-if="actData.company.region">
                <i class="iconfont icon-dizhi icon"></i>
                <span>{{ actData.company.region }}</span>
              </div>
              <div class="u-category">
                <i class="iconfont icon-biaoqian1 icon"></i>
                <span>{{ actData.exhibitor.category }}</span>
              </div>
            </div>
            <div class="product-renzheng" v-if="actData.exhibitor.continuouNumber">
              <div>
                <img src="@/assets/img/jinpai.png" v-if="actData.exhibitor.continuouNumber > 7" />
                <img src="@/assets/img/tongpai.png" v-else-if="actData.exhibitor.continuouNumber < 5" />
                <img src="@/assets/img/yinpai.png" v-else />
              </div>
              <span> 连续5年参展 </span>
            </div>
          </div>
        </div>
        <div class="center">
          <div>
            <div style="color: rgb(255, 94, 0);font-size: 18px;">{{ actData.exhibitor.productNumber }}</div>
            <div>展品</div>
          </div>
          <div>
            <div style="color: rgb(255, 94, 0);font-size: 18px;">{{ actData.exhibitor.activityNumber }}</div>
            <div>活动</div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-card3">
      <div class="code">
        <div id="qrcode" class="qrcode"></div>
        <span>扫码查看移动端</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex";
import QRCode from "qrcodejs2";
import url from "@/utils/url";
export default {
  props: ["actData"],
  data() {
    return {
      url:url,
      category: "", 
    };
  },
  mounted() {
    if (this.actData.exhibitor && this.actData.exhibitor.category && this.actData.exhibitor.category.indexOf("[") !== -1) {
      let category = JSON.parse(this.actData.exhibitor.category);
      this.actData.exhibitor.category = this.getCate(category);
    }
    this.getQrCode();
  },
  computed: {
    ...mapGetters({
      exhibitionInfo: "getExhibitionInfo",
    }),
  }, 
  methods: {
    ...mapActions({
      linkToExhibitorDetails:'linkTo/linkToExhibitorDetails'
    }),
    getQrCode() {
      let url2 = "";
      if (this.$route.name == "activity-details") {
        // 有直播
        url2 = `https://${this.url.mobile}/live?actId=${this.actData.id}`;
      } else {
        url2 = `https://${this.url.mobile}/nolive?actId=${this.actData.id}`;
      } 
      console.log('url.mobile', this.url.mobile);
      let qrcode = new QRCode("qrcode", {
        width: 180,
        height: 180,
        text: url2, // 二维码内容
        correctLevel: 3,
      });
    },
    //类别转化
    getCate(arr) {
      let str = "";
      if (arr && arr[0]) {
        for (let i = 0; i < arr.length; i++) {
          str += str ? "," + arr[i].Value : arr[i].Value;
        }
        return str;
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.compinfo {
  &-main {
    padding: 20px;
  }
  .top {
    display: flex;
    width: 100%;
    cursor: pointer;
    .logo {
      img {
        width: 78px;
        height: 78px;
        display: block;
        margin-right: 10px;
      }
    }
  }
  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 190px;
  }
  &-type {
    display: flex;
    margin-top: 5px;
  }
  .u-booth {
    display: flex;
    align-items: center;
    margin-right: 12px;
    span {
      display: inline-block;
      margin-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 70px;
    }
  }
  .u-category {
    display: flex;
    align-items: center;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 70px;
    }
  }
  .product-renzheng {
    width: 100px;
    height: 22px;
    border: solid 1px #ffe7d9;
    margin-top: 7px;
    display: flex;
    align-items: center;
    background-color: #ffe7d9;
    div {
      background-color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
      justify-content: center;
    }
    span {
      display: inline-block;
      margin-left: 6px;
      font-size: 12px;
      color: rgb(255, 94, 0);
    }
  }
  .center {
    width: 146px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-top: 15px;
  }
  .code {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    span {
      display: inline-block;
      margin-top: 20px;
    }
  }
}
</style>
