<template>
  <!-- 直播倒计时 -->
  <div class="live-ing"> 
    <!-- <div class="live-ing-title">{{roomInfo.name}}</div>
    <div class="live-ing-txt">直播倒计时</div>
    <div class="live-ing-count">{{txt}}</div> -->
    <div class="live-ing-txt">主播暂未开播</div>
  </div>
</template>

<script>
// import { countDown } from "../../utils/utils";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "live-ing",
  data() {
    return {
      txt: "",
      show: true
    };
  },
  computed: {
    // ...mapGetters({
    //   roomInfo: "getRoomInfo"
    // })
  },
  created(){
    this.setLive(); 
  },
  methods: {
    setLive() {
      // 设置直播倒计时
      // const now = new Date().getTime();
      // const start = new Date(this.roomInfo.planStartTime).getTime();
      // if (start > now) {
      //   // 可直接开直播
      //   setInterval(() => {
      //     this.txt = countDown(this.roomInfo.planStartTime);
      //   }, 1000);
      // } else {
      //   this.show = false;
      // }
    }
  }, 
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
/* 直播倒计时显示 */

.live-ing {
  width: 100%;
//   top: 45%;
  left: 0;
  position: absolute;
  z-index: 98;
  text-align: center;
  color: #fff;
  line-height: 1;
  height: 100%;
  background: rgba(0,0,0,.4);
}

.live-ing-title {
  font-size: 24px;
  margin-bottom: 56px;
}

.live-ing-txt {
  font-size: 30px;
  margin-bottom: 20px;
  margin-top: 200px;
}

.live-ing-count {
  font-size: 0.48rem;
}
</style>