<template>
  <div class="live_dianzan">
    <!-- <div class="praise_bubble">
      <div class="bubble" :class="item.className" v-for="(item,index) in dianzanList" :key="index">
        <img :src="item.img" >
      </div>
    </div> -->
    <canvas id="thumsCanvas" width="200" height="400" style="width:100px;height:200px;"></canvas>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "live-dianzan",
  data() {
    return {
      imgsList : [],
      dianzanList:[],
      context:null,
      isOk:false,
      width : 0,
      height : 0,
      scanning : false,
      timeOut:null,
      renderList:[],
      imgL:[
        'https://img.zhanshangxiu.com/showonline/file/202006/6beee8b23f8d4f6d99b837cb45b2e3d2.png', //掌声zi
        'https://img.zhanshangxiu.com/showonline/file/202006/dfb5e3f8bbbf42c9921029f818c366f5.png',//掌声白
        'https://img.zhanshangxiu.com/showonline/file/202006/ce32dd5317c8439588b800eca73d64c4.png', //掌声红
        'https://img.zhanshangxiu.com/showonline/file/202006/c10d03b052fc48a3a8d709ab33a9b017.png',//掌声蓝
        'https://img.zhanshangxiu.com/showonline/file/202006/3aceaf17f67b480f973b0557f37d1c18.png',//掌声绿
        'https://img.zhanshangxiu.com/showonline/file/202006/d29b125a8aa148b4a91cee0f991f1d64.png',  //掌声奶白紫
        // 'https://img.zhanshangxiu.com/showonline/file/202008/a2f848ffa7d54ebaab07079882080eb2.png', //透明图
      ],
      scaleTime : 0.15,// 百分比
    };
  },
  computed: {
    ...mapGetters({
     
    })
  },
  created(){
     
  },
  methods: {
    init(){
      this.loadImages();
      const canvas = document.getElementById('thumsCanvas');
      this.context = canvas.getContext('2d');
      this.width = canvas.width;
      this.height = canvas.height;
    },
    loadImages() {
      const promiseAll = [];
      this.imgL.forEach((src) => {
          const p = new Promise(function (resolve) {
              const img = new Image;
              img.onerror = img.onload = resolve.bind(null, img);
              img.src = src;
          });
          promiseAll.push(p);
      });
      Promise.all(promiseAll).then((imgsList) => {
          this.imgsList = imgsList.filter((d) => {
              if (d && d.width > 0) return true;
              return false;
          });
          if (this.imgsList.length == 0) {
              dLog('error', 'imgsList load all error');
              return;
          }
          this.isOk=true;
      })
    },
    getRandom(min, max) {
        return min + Math.floor(Math.random() * (max - min + 1))
    },
    createRender() {
        if (this.imgsList.length == 0) return null;
        const basicScale = [0.6, 0.9, 1.2][this.getRandom(0, 2)];
        const getScale = (diffTime) => {
            if (diffTime < this.scaleTime) {
                return +((diffTime/ this.scaleTime).toFixed(2)) * basicScale;
            } else {
                return basicScale;
            }
        };
        const context = this.context;
        // 随机读取一个图片来渲染
        const image = this.imgsList[this.getRandom(0, this.imgsList.length - 1)]
        const offset = 20;
        const basicX = this.width / 2 + this.getRandom(-offset, offset);
        const angle = this.getRandom(2, 10);
        let ratio = this.getRandom(10,30)*((this.getRandom(0, 1) ? 1 : -1));
        const getTranslateX = (diffTime) => {
            if (diffTime < this.scaleTime) {// 放大期间，不进行摇摆位移
                return basicX;
            } else {
                return basicX + ratio*Math.sin(angle*(diffTime - this.scaleTime));
            }
        };

        const getTranslateY = (diffTime) => {
           return image.height / 2 + (this.height - image.height / 2) * (1-diffTime);
        };

        const fadeOutStage = this.getRandom(14, 18) / 100;
        const getAlpha = (diffTime) => {
            let left = 1 - +diffTime;
            if (left > fadeOutStage) {
                return 0.8;
            } else {
                return 1 - +((fadeOutStage - left) / fadeOutStage).toFixed(2);
            }
        };

        return (diffTime) => {
            // 差值满了，即结束了 0 ---》 1
            if(diffTime>=1) return true;
            context.save();
            const scale = getScale(diffTime);
            // const rotate = getRotate();
            const translateX = getTranslateX(diffTime);
            const translateY = getTranslateY(diffTime);
            context.translate(translateX, translateY);
            context.scale(scale, scale);
            // context.rotate(rotate * Math.PI / 180);
            // console.log(context,'绘制');
            context.globalAlpha = getAlpha(diffTime);
            context.drawImage(
                image,
                -image.width / 2,
                -image.height / 2,
                image.width,
                image.height
            );
            context.restore();
        };
    },
    scan() {
        this.context.clearRect(0, 0, this.width, this.height);
        this.context.fillStyle = "rgba(0,0,0,0)";
        this.context.fillRect(0,0,200,400);
        let index = 0;
        let length = this.renderList.length;
        if (length > 0) {
            this.requestFrame(this.scan.bind(this));
            this.scanning = true;
        } else {
            this.scanning = false;
        }
        while (index < length) {
            const child = this.renderList[index];
            if (!child || !child.render || child.render.call(null, (Date.now() - child.timestamp) / child.duration)) {
                // 结束了，删除该动画
                this.renderList.splice(index, 1);
                length--;
            } else {
                // continue
                index++;
            }
        }
    },
    start() {
      const render = this.createRender();
      const duration = this.getRandom(1500, 3000);
      this.renderList.push({
          render,
          duration,
          timestamp: Date.now(),
      });
      if (!this.scanning) {
          this.scanning = true;
          this.requestFrame(this.scan.bind());
      }
      return this;
    },
    requestFrame(cb) {
      return (
        function(callback) {
          setTimeout(callback, 1000 / 60);
        }
      )(cb);
    },
  },
  mounted(){
    this.init()
    this.$Bus.$off('dzNotice');
    this.$Bus.$on('dzNotice',()=>{
      // this.pushDianzan();
      if(this.isOk){
        this.start();
      }
    });
    // this.loadImages();
  },
  beforeDestroy(){
  }
};
</script>


<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";

.live_dianzan{
  position: absolute;
  z-index: 200;
  right: 30px;
  bottom: 40px;
  width: 100px;
  height: 200px;
  pointer-events: none;
  // transform: translateX(-140px);
}
</style>
