<template>
  <div v-if="dataList.length">
    <div class="img-live" v-for="(item,index) in dataList" :key="index">
        <h3>{{item.title || item.content }}</h3>
        <p>{{item.createAt | moment("YYYY/MM/DD HH:mm:ss")}}</p>
        <div class="img-live-list" :class="item.isShow ? 'over-img' : ''">
            <img :src="c.url" 
            v-for="(c,index) in item.images"
            :key="index"
            @click="imgClick(item.images)"
            >
        </div>
        <div class="view-more" @click="open(item)" :class="item.isShow ? 'over-show' : 'over-hide'">查看更多</div>
    </div>
    <ShowSwiper :show="show" :imgList="imgList"></ShowSwiper>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ShowSwiper from "@components/ShowSwiper/ShowSwiper";
export default {
    data(){
        return{
          loading: false,
          finished: false,
          pageSize: 20,
          pageIndex: 1,
          dataList:[],
          queryLiveImageText:{},
          imgList: [],
          show:false,
        }
    },
    components: {
      ShowSwiper
    },
    computed:{
      ...mapGetters({
        exhibitionId:'getExhibitionId',
      }),
      actId(){
        return this.$route.query.id;
      },
    },
    created(){
      this.getDataList();
    },
    methods: {
      ...mapActions({
        graphqlPost:'graphqlPostByZXS',
      }),
      async getDataList(){
        let query = `
          query queryLiveImageText($activityId: Guid!,$page:Int, $size:Int){
            queryLiveImageText(activityId: $activityId,page:$page,size:$size){
                hasNextPage
                hasPreviousPage
                items{
                  createAt
                  content
                  id
                  imageCount
                  images(num:50){
                    url
                  }
                  origin
                  title
                }
                pageIndex
                pageSize
                totalCount
                totalPages
            }
          }
        `;
        let opt = {
            query: query,
            variables:{
              activityId:this.actId,
              page:this.pageIndex,
              size:this.pageSize,
            }
        };
        let res = await this.graphqlPost(opt);
        if(res.data.queryLiveImageText){
          let data = JSON.parse(JSON.stringify(res.data))
          if(data && data.queryLiveImageText.items){
            let currentList = data.queryLiveImageText.items;
            currentList.map(c=>{
                if(c.images.length > 4){
                  c.isShow = true;
                } else {
                  c.isShow = false;
                }
              })
            if(this.pageIndex == 1){
              this.dataList = currentList;
            } else {
              this.dataList = [...this.dataList,...currentList]
            }
            this.loading = false;
            if (!data.queryLiveImageText.hasNextPage) {
              this.finished = true;
            }
            if(this.dataList.length){
              this.$emit('finishImg',true)
            } else {
              this.$emit('finishImg',false)
            }
          } else {
            if(this.dataList.length){
              this.$emit('finishImg',true)
            } else {
              this.$emit('finishImg',false)
            }
          }
        } else {
            this.$emit('finishImg',false)
        }
      },
    
      open(item){
        this.dataList.map(c=>{
          if(c.id == item.id) {
            c.isShow = false
          }
        })
      },

      imgClick(imgList) {
        let arr = [];
        imgList.forEach(c => {
          arr.push(c.url);
        });
        this.show = true
        this.imgList = arr;
      },
      closeSwiper(){
        this.show=false
      },


    },
    
}
</script>
<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.img-live{
  width: 100%;
	background-color: #f7f7f7;
  border-radius: 5px;
  padding:20px 14px;
  margin-bottom: 10px;
  h3{
    font-weight: normal;
    font-size: 14px;
    color: #ff5c00;
  }
  p{
    font-size: 12px;
    color: #666;
    margin-bottom: 4px;
  }
  &-list{
    display: flex;
    flex-wrap: wrap;
    img{
      display: block;
      width: 195px;
      height: 195px;
      margin-right: 10px;
      margin-top: 10px;
    }
    img:nth-child(4n+4){
      margin-right: 0;
    }
  }
  .view-more{
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    color: #999;
  }
}
.over-img{
  max-height: 205px;
  overflow: hidden;
}
.over-show{
  display: block;
}
.over-hide{
  display: none;
}
</style>